<template>
  <div>
    <div class="content-1to7button">
      <app-form-vertical-button
        v-model="inputValue"
        :items="options"
        item-value="value"
        item-text="label"
        v-on:change.native="response(inputValue)"
        :isGradient="question.gradient"
        :textColor="colors.actionText"
        :labelColor="colors.splashColor"
        :bgColor="colors.actionColor"
        :hoverColor="colors.buttonHover"
      />
    </div>
  </div>
</template>

<script>
import { translator, gradientMaker } from '@/_helpers'

export default {
  name: 'app1to7button',
  props: ['question', 'colors'],
  components: {
    'app-form-vertical-button': () => import('@binds-tech/binds-design-system/src/components/Form/VerticalButton')
  },
  data () {
    return {
      inputValue: null
    }
  },
  created () {
    if (this.question.language) {
      this.$i18n.locale = translator.getCurrentLocale(this.question.language)
    }
  },
  mounted () {
    this.$root.$on('footer-submit', payload => {
      if (payload) {
        this.nextQuestion({ skip: true })
      }
    })
  },
  computed: {
    options () {
      const options = [
        { label: this.$i18n.t('ces.totally_disagree'), value: 0 },
        { label: this.$i18n.t('ces.disagree'), value: 15 },
        { label: this.$i18n.t('ces.partially_disagree'), value: 30 },
        { label: this.$i18n.t('ces.neutral'), value: 50 },
        { label: this.$i18n.t('ces.partially_agree'), value: 65 },
        { label: this.$i18n.t('ces.agree'), value: 80 },
        { label: this.$i18n.t('ces.totally_agree'), value: 100 }
      ]
      gradientMaker.checkGradientAndReturnTheColors(this.question, options)
      return options
    }
  },
  methods: {
    response (value) {
      const findValue = this.options.find(s => s.value === value).label
      const body = { rating: value, value: findValue }
      this.nextQuestion(body)
    },
    nextQuestion (body) {
      this.$emit('value-changed', body)
      this.$emit('question-answered', true)
    }
  }
}
</script>
<style lang="scss">
.content-1to7button {
  height: 370px;
  display: flex;
  align-items: center;
  justify-content: center;
  .rating {
    width: 100%;
    height: 100%;
    padding-top: 10px;
    .rating__item {
      display: flex !important;
      align-items: center;
      justify-content: center;
      .rating__button {
        width: 220px;
        height: 35px;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

// Responsivity to mobile
@media only screen and (max-width: 850px) {
  .content-1to7button {
    height: 325px;
    .rating {
      padding: 0;
    .rating__item {
      .rating__button {
        font-size: 15px;
      }
    }
    }
  }
}
</style>
